@import "colors";
@import "variables";

.alert {
  position: relative;
  padding: .75em 1.25em;
  margin-bottom: 1em;
  border: .0625em solid transparent;
  border-radius: .25em;
}

.alert-sm {
  padding: .25em .5em;
  font-size: $font-size-3;
  line-height: 1.5;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 500;
}

.alert-dismissible {
  padding-right: 4em;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: .75em 1.25em;
  color: inherit;
}

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    color: darken($value, 10%);

    @if $color == 'warning' {
      background-color: lighten($value, 30%);
    } @else {
      background-color: lighten($value, 50%);
    }

    border-color: lighten($value, 45%);

    hr {
      border-top-color: lighten($value, 10%);
    }

    .alert-link {
      color: darken($value, 15%);
    }
  }
}
