@import "variables";
@import "colors";

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75em + .125em);
  padding: .375em .75em;
  font-size: $font-size;
  font-weight: 400;
  line-height: 1.5;
  background-color: $background-color;
  background-clip: padding-box;
  border: $border-width $border-style $border-color;
  border-radius: $border-radius;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 $text-shadow-color;
}

.form-control:focus {
  color: $font-color;
  background-color: $background-color;
  border-color: $border-color-2;
  outline: 0;
  box-shadow: 0 0 0 $box-shadow-spread $box-shadow-color;
}

.form-control::-webkit-input-placeholder {
  color: $placeholder-color;
  opacity: $placeholder-opacity;
}

.form-control::-moz-placeholder {
  color: $placeholder-color;
  opacity: $placeholder-opacity;
}

.form-control:-ms-input-placeholder {
  color: $placeholder-color;
  opacity: $placeholder-opacity;
}

.form-control::-ms-input-placeholder {
  color: $placeholder-color;
  opacity: $placeholder-opacity;
}

.form-control::placeholder {
  color: $placeholder-color;
  opacity: $placeholder-opacity;
}

.form-control:disabled, .form-control[readonly] {
  background-color: $background-color-2;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: $primary-text-color;
  background-color: $background-color;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(.375em + 1px);
  padding-bottom: calc(.375em + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5em + .0625em);
  padding-bottom: calc(.5em + .0625em);
  font-size: $font-size-4;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + .0625em);
  padding-bottom: calc(0.25rem + .0625em);
  font-size: $font-size-3;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: .375em 0;
  margin-bottom: 0;
  font-size: $font-size;
  line-height: 1.5;
  color: $dark-gray;
  background-color: transparent;
  border: solid transparent;
  border-width: $border-width-2 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  // height: calc(1.5em + .5em + .125em);
  padding: .25em .5em;
  font-size: $font-size-3;
  line-height: 1.5;
  border-radius: $border-radius;
}

.form-control-lg {
  height: calc(1.5em + 1em + .125em);
  padding: .5em 1em;
  font-size: $font-size-4;
  line-height: 1.5;
  border-radius: $border-radius;
}

.form-group {
  margin-bottom: 1em;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25em;
}

.form-check-input {
  position: absolute;
  margin-top: .3em;
  margin-left: -1.25em;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: $text-disabled-color;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: .75em;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125em;
  margin-left: 0;
}

//buttons
.btn {
  display: inline-block;
  font-family: $font-family;
  color: $primary-text-color;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: $border-width-2 $border-style transparent;
  padding: .375em .75em;
  font-size: $font-size;
  line-height: 1.5;
  border-radius: $border-radius;
  outline: none !important;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  svg {
    margin-top: -.25em;
    width: 1em;
    height: 1em;
  }

  &:hover {
    outline: none !important;
    color: $font-color;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: none !important;
    box-shadow: 0 0 0 .2em $box-shadow-color-3;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    outline: none !important;
    opacity: .65;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: $font-color-6;
    background-color: $background-color-7;
    border-color: $border-color-2;

    &:focus {
      outline: none !important;
      box-shadow: 0 0 0 .2em $box-shadow-color-4;
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}


@each $color, $value in $theme-colors {
  .btn-#{$color} {
    color: $font-color-6;
    background-color: $value;

    &:hover {
      outline: none !important;
      color: $font-color-6;
      background-color: darken($value, 5%);
      border-color: darken($value, 5%);
    }

    &:focus, &.focus {
      color: $font-color-6;
      outline: none !important;
      background-color: darken($value, 5%);
      border-color: darken($value, 5%);
      box-shadow: 0 0 0 .2em rgba($value, .5);
    }

    &:disabled, &.disabled {
      color: $font-color-6;
      outline: none !important;
      background-color: lighten($value, 5%);
      border-color: lighten($value, 5%);
    }

    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
      color: $font-color-6;
      outline: none !important;
      background-color: lighten($value, 5%);
      border-color: lighten($value, 5%);
      box-shadow: 0 0 0 .2em rgba($value, .5);

      &:focus {
        outline: none !important;
        box-shadow: 0 0 0 .2em rgba($value, .55);
      }
    }
  }
}

$breakpoint-map: "btn-destak-gold" $primary-yellow $gradient-1 $gradient-1-inverse $accent-gray,
"btn-destak-orange" $primary-orange $gradient-2 $gradient-2-inverse $accent-gray,
"btn-destak-purple" $primary-purple $gradient-4 $gradient-4-inverse $color-black,
"btn-ice-gray" $accent-gray $gradient-3 $gradient-3-inverse $accent-gray,
"btn-destak-blue" $primary-blue $gradient-8 $gradient-8-inverse $accent-gray;
@each $name, $primary, $bg-1, $bg-2, $border in $breakpoint-map {
  .#{$name} {
    color: $font-color-2;
    background: $bg-1;
    border-color: rgba($border, .7);
    font-weight: 500;

    &:hover {
      outline: none !important;
      color: $font-color-2;
      background: $bg-2;
      border-color: rgba($border, .7);
    }

    &:focus, &.focus {
      color: $font-color-2;
      outline: none !important;
      background-color: $bg-2;
      border-color: rgba($border, .7);
      box-shadow: 0 0 0 .2em rgba($primary, .5);
    }

    &:disabled, &.disabled {
      color: $font-color-2;
      outline: none !important;
      background-color: lighten($primary, 5%);
      border-color: rgba($border, .3);
    }

    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
      color: $font-color-2;
      outline: none !important;
      background: $bg-1;
      border-color: rgba($border, .7);
      box-shadow: 0 0 0 .2em rgba($primary, .5);

      &:focus {
        outline: none !important;
        box-shadow: 0 0 0 .2em rgba($primary, .55);
      }
    }
  }
}

.btn-link {
  color: $font-color-6;
  text-decoration: none;

  &:hover {
    color: $font-color;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    text-decoration: none;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $accent-gray;
    pointer-events: none;
  }
}

.btn-lg, .btn-group-lg > .btn {
  padding: .5em 1em;
  font-size: $font-size-4;
  line-height: 1.5;
  border-radius: $border-radius-2;
}

.btn-sm, .btn-group-sm > .btn {
  padding: .25em .5em;
  font-size: $font-size-3;
  line-height: 1.5;
  border-radius: $border-radius;

  svg {
    margin-top: -.125em;
    width: .875em;
    height: .875em;
  }
}

.btn-block {
  display: block;
  width: 100%;
}

input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
